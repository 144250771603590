import React, { Component } from "react";
import logo from "./images/logoFull.png";
import { withTranslation } from 'react-i18next';
import "./landing.css";




class Privacy extends Component {
    constructor(props) {
        super(props);
    }




    render() {
        const { t } = this.props;
        return (
            <>
                <div className="innerReg common-content">
                    <div className="container-fluid">
                        <img className="logo" src={logo} alt="logo"></img>
                        {/* <div className="privacyDiv">
                            <p className="privacyTitle">PRIVACY POLICY MODEL FOR MOBILE APPLICATIONS</p>
                            <p className="privacyDesc">This privacy policy governs your use of the software application and site Bar-zone.com & Barzone for mobile devices that was created by Sorokin. The Application is a social application to keep track of events & friends, and to notify users when & where.</p>
                            <p className="privacyQ">What information does the Application obtain and how is it used?</p>
                            <p className="privacyA">User Provided Information The Application obtains the information you provide when you download and register the Application and on site. Registration with us is optional. However, please keep in mind that you may not be able to use some of the features offered by the Application unless you register with us.</p>
                            <p className="privacyA">When you register with us and use the Application, you generally provide (a) your name, email address, age, user name, password and other registration information; (b) transaction-related information, such as when you make purchases, respond to any offers, or download or use applications from us; (c) information you provide us when you contact us for help; (d) credit card information for purchase and use of the Application, and; (e) information you enter into our system when using the Application, such as contact information and project management information.</p>
                            <p className="privacyA">We may also use the information you provided us to contact your from time to time to provide you with important information, required notices and marketing promotions.</p>
                            <p className="privacyA">Automatically Collected Information In addition, the Application may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile devices unique device ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browsers you use, and information about the way you use the Application.</p>
                            <p className="privacyQ">Does the Application collect precise real time location information of the device?</p>
                            <p className="privacyA">When you visit the mobile application, we may use GPS technology (or other similar technology) to determine your current location in order to determine the city you are located within and display a location map with relevant advertisements. We will not share your current location with other users or partners.</p>
                            <p className="privacyA">If you do not want us to use your location for the purposes set forth above, you should turn off the location services for the mobile application located in your account settings or in your mobile phone settings and/or within the mobile application.</p>
                            <p className="privacyQ">Do third parties see and/or have access to information obtained by the Application?</p>
                            <p className="privacyA">Yes. We will share your information with third parties only in the ways that are described in this privacy statement.</p>
                            <p className="privacyA">We may disclose User Provided and Automatically Collected Information:</p>
                            <p className="privacyA">as required by law, such as to comply with a subpoena, or similar legal process;
                            when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request;
                            with our trusted services providers who work on our behalf, do not have an independent use of the information we disclose to them, and have agreed to adhere to the rules set forth in this privacy statement.
                            if Sorokin is involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email and/or a prominent notice on our Web site of any change in ownership or uses of this information, as well as any choices you may have regarding this information.</p>
                            <p className="privacyQ">What are my opt-out rights?</p>
                            <p className="privacyA">You can stop all collection of information by the Application easily by uninstalling the Application. You may use the standard uninstall processes as may be available as part of your mobile device or via the mobile application marketplace or network. You can also request to opt-out via email, at <a className="privacyLink" href="mailto:contact@bar-zone.com">contact@bar-zone.com</a>.</p>
                            <p className="privacyQ">Data Retention Policy, Managing Your Information</p>
                            <p className="privacyA">We will retain User Provided data for as long as you use the Application and for a reasonable time thereafter. We will retain Automatically Collected information for up to 24 months and thereafter may store it in aggregate. If you’d like us to delete User Provided Data that you have provided via the Application, please contact us at <a className="privacyLink" href="mailto:contact@bar-zone.com">contact@bar-zone.com</a>, we will respond in a reasonable time. Please note that some or all of the User Provided Data may be required in order for the Application to function properly.</p>
                            <p className="privacyQ">Children</p>
                            <p className="privacyA">We do not use the Application to knowingly solicit data from or market to children under the age of legal age in their country. If a parent or guardian becomes aware that his or her child has provided us with information without their consent, he or she should contact us at <a className="privacyLink" href="mailto:contact@bar-zone.com">contact@bar-zone.com</a>. We will delete such information from our files within a reasonable time.</p>
                            <p className="privacyQ">Security</p>
                            <p className="privacyA">We are concerned about safeguarding the confidentiality of your information. We provide physical, electronic, and procedural safeguards to protect information we process and maintain. For example, we limit access to this information to authorized employees and contractors who need to know that information in order to operate, develop or improve our Application. Please be aware that, although we endeavor provide reasonable security for information we process and maintain, no security system can prevent all potential security breaches.</p>
                            <p className="privacyQ">Changes</p>
                            <p className="privacyA">This Privacy Policy may be updated from time to time for any reason. We will notify you of any changes to our Privacy Policy by posting the new Privacy Policy here and informing you via email or text message. You are advised to consult this Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.</p>
                            <p className="privacyQ">Your Consent</p>
                            <p className="privacyA">By using the Application, you are consenting to our processing of your information as set forth in this Privacy Policy now and as amended by us. “Processing,” means using cookies on a computer/hand held device or using or touching information in any way, including, but not limited to, collecting, storing, deleting, using, combining and disclosing information, all of which activities will take place in Sweden. If you reside outside the Sweden your information will be transferred, processed and stored there under Sweden privacy standards.</p>
                            <p className="privacyQ">Contact us</p>
                            <p className="privacyA">If you have any questions regarding privacy while using the Application, or have questions about our practices, please contact us via email at <a className="privacyLink" href="mailto:contact@bar-zone.com">contact@bar-zone.com</a>.</p>
                        
                        </div> */}

                        <div className="privacyDiv">
                            <h2>Privacy Policy for Bar-Zone</h2>
                            <p>Effective Date: 2024.12.15</p>
                            <p>At <b>Bar-Zone.com</b>, we respect your privacy and are committed to protecting your personal data. This Privacy Policy explains how we collect, use, and disclose your information, ensuring compliance with the <b>General Data
                                Protection Regulation (GDPR)</b> and the <b>California Consumer Privacy Act (CCPA)</b>.</p>

                            <div className="contentbox">
                                <h3>1. Information We Collect</h3>
                                <p>We collect the following types of personal data when you use our app:</p>
                                <ul>
                                    <li><b>a. Personal Information</b>
                                        <ul>
                                            <li><b>Provided by You: </b>Name, email address, phone number, payment information, etc.</li>
                                            <li><b>Automatically Collected: </b>IP address, device information, app usage data, location data.</li>
                                        </ul>
                                    </li>
                                    <li><b>b. Sensitive Information</b>
                                        <ul className="pl-0">
                                            <li className="list-style-none">We may collect sensitive data only with explicit consent (e.g., biometric data for authentication).</li>
                                        </ul>
                                    </li>
                                </ul>
                                <div className="border-bottom"></div>
                            </div>

                            <div className="contentbox">
                                <h3>2. How We Use Your Information</h3>
                                <p>We use your information for the following purposes:</p>
                                <ol className="ol-main">
                                    <li><b>Provide and Improve Services: </b>Personalize user experiences and enhance app functionality.</li>
                                    <li><b>To Communicate: </b>Send important updates, newsletters, or promotional content (with opt-in).</li>
                                    <li><b>For Compliance and Security:</b>Detect fraud, enforce terms of service, and comply with legal obligations.</li>
                                </ol>
                                <div className="border-bottom"></div>
                            </div>



                            <div className="contentbox">
                                <h3>3. Legal Bases for Processing (GDPR)</h3>
                                <p>We process your data under the following legal bases:</p>
                                <ul className="ul-main">
                                    <li><b>Consent: </b>When you provide explicit consent.</li>
                                    <li><b>Contract:</b>To perform obligations under a contract.</li>
                                    <li><b>Legal Obligation:</b>To comply with applicable laws.</li>
                                    <li><b>Legitimate Interest:</b>When processing is necessary for legitimate business interests, except where
                                        overridden by your rights.</li>

                                </ul>
                                <div className="border-bottom"></div>
                            </div>





                            <div className="contentbox">
                                <h3>4. Your Rights Under GDPR</h3>
                                <p>As an EU resident, you have the following rights:</p>
                                <ul className="ul-main">
                                    <li><b>Access: </b>Request a copy of your personal data.</li>
                                    <li><b>Correction: </b>Update inaccurate or incomplete data.</li>
                                    <li><b>Erasure: </b>Request deletion of your data (“Right to be Forgotten”).</li>
                                    <li><b>Restriction: </b>Limit how we process your data.</li>
                                    <li><b>Portability: </b>Receive your data in a structured, machine-readable format.</li>
                                    <li><b>Objection: </b>Object to data processing for marketing or legitimate interest.</li>
                                    <li><b>Withdraw Consent: </b>Revoke consent at any time.</li>
                                </ul>
                                <p>To exercise these rights, please contact us at <a href="mailto:support@bar-zone.com"><b>support@bar-zone.com </b></a>.</p>
                                <div className="border-bottom"></div>
                            </div>

                            <div className="contentbox">
                                <h3>5. Your Rights Under CCPA</h3>
                                <p>As a California resident, you have the following rights:</p>
                                <ul className="ul-main">
                                    <li><b>Know: </b>Request details about the categories and specific pieces of personal information collected.</li>
                                    <li><b>Delete: </b>Ask us to delete your personal data, subject to exceptions.</li>
                                    <li><b>Opt-Out: </b>Decline the sale or sharing of your personal data.</li>
                                    <li><b>Non-Discrimination: </b>Enjoy services and prices regardless of exercising your rights.</li>
                                </ul>
                                <p>To exercise these rights, submit a verifiable request at [Your Contact Email or Web Form].</p>
                                <div className="border-bottom"></div>
                            </div>

                            <div className="contentbox">
                                <h3>6. Data Sharing and Transfers</h3>
                                <p>We may share your data with:</p>
                                <ul className="ul-main">
                                    <li><b>Service Providers: </b>For hosting, analytics, payment processing, etc.</li>
                                    <li><b>Legal Authorities: </b>If required by law or to enforce our rights.</li>
                                    <li><b>Third Parties: </b>With explicit consent.</li>
                                </ul>
                                <p>For international transfers, we ensure your data is protected under GDPR-approved mechanisms like Standard Contractual Clauses (SCCs).</p>
                                <div className="border-bottom"></div>
                            </div>

                            <div className="contentbox">
                                <h3>7. Data Retention</h3>
                                <p>We retain your data only as long as necessary for:</p>
                                <ul className="ul-main">
                                    <li>Providing services.</li>
                                    <li>Complying with legal obligations.</li>
                                    <li>Resolving disputes.</li>
                                </ul>
                                <p>When no longer needed, we securely delete or anonymize your data.</p>
                                <div className="border-bottom"></div>
                            </div>

                            <div className="contentbox">
                                <h3>8. Security Measures</h3>
                                <p>We implement robust technical and organizational measures, including encryption, two-factor authentication, and regular security audits, to protect your data.</p>
                                <div className="border-bottom"></div>
                            </div>

                            <div className="contentbox">
                                <h3>9. Cookies and Tracking Technologies</h3>
                                <p>We use cookies and similar technologies to enhance your app experience:</p>
                                <ul className="ul-main">
                                    <li><b>Strictly Necessary Cookies: </b>Required for core functionalities.</li>
                                    <li><b>Performance Cookies: </b>To analyze app usage.</li>
                                    <li><b>Marketing Cookies: </b>For personalized ads (with consent).</li>
                                </ul>
                                <p>You can manage cookie preferences via app settings.</p>
                                <div className="border-bottom"></div>
                            </div>

                            <div className="contentbox">
                                <h3>10. Updates to This Privacy Policy</h3>
                                <p>We may update this policy periodically. We will notify you of significant changes through the app or by email.</p>
                                <div className="border-bottom"></div>
                            </div>

                            <div className="contentbox">
                                <h3>11. Contact Us</h3>
                                <p>If you have questions or concerns about this Privacy Policy, contact us at:</p>
                                <ul className="ul-main">
                                    <li>Email: <a href="mailto:support@bar-zone.com"><b>support@bar-zone.com </b></a></li>
                                </ul>
                                <p>For unresolved concerns, EU residents can contact their local Data Protection Authority, and California residents can reach the California Attorney General.</p>
                            </div>

                            <p>By using <b>Bar-Zone</b>, you acknowledge and agree to the terms of this Privacy Policy.</p>

                        </div>
                    </div>
                </div >
            </>
        );
    }

}

export default withTranslation()(Privacy);
